<template>
  <base-section
    id="k-d-a-home-proses"
    space="80"
    top-inner-shadow
    style="background-color: #f8f8f8"
  >
    <v-container class="pa-0">
      <v-row
        align="center"
        justify="center"
        no-gutters
        :style="{ padding: g_bBaseBr ? '44px 24px' : 0 }"
      >
        <v-col v-if="g_bBaseBr" cols="12">
          <component
            :is="m_objTitle.strComponent"
            :class="`kda-ts-${g_bLowestBr ? '20' : '28'}pt nunito wt-semibold`"
            style="padding: 10px 0"
            v-html="m_objTitle.htmlText"
          />
        </v-col>

        <v-col :class="`${g_bBaseBr ? 'col-12' : 'col-xl-auto'}`">
          <base-img
            :src="require('@/assets/photos/home-proses.png')"
            :style="{
              maxHeight: g_bLowestBr ? '200px' : '608px',
              paddingRight: g_bBaseBr ? 0 : `100px`
            }"
            position="left"
            :contain="!g_bLowestBr"
            :fill="g_bLowestBr"
          >
          </base-img>
        </v-col>

        <v-col :class="`${g_bBaseBr ? 'col-12' : 'col-xl-auto'}`">
          <component
            :is="m_objTitle.strComponent"
            v-if="!g_bBaseBr"
            class="kda-ts-28pt wt-medium"
            style="padding: 10px 0"
            v-html="m_objTitle.htmlText"
          />
          <component
            :is="m_objDescription.strComponent"
            :class="`kda-ts-${g_bLowerBr ? '20' : '36'}pt wt-bold ln-150`"
            style="padding: 10px 0"
            v-html="m_objDescription.htmlText"
          />

          <v-btn
            class="text-capitalize main-r-400 px-10 white--text"
            :x-large="!g_bLowestBr"
            @click="navToProcess"
          >
            {{ m_strButton }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';
import TextClass from '@/mixins/text-class';

export default {
  name: 'KDAHomeProcess',

  mixins: [TextClass, BusinessDetails, BaseBreakpoint],

  computed: {
    m_objTitle() {
      return {
        htmlText: this.$vuetify.lang.t('$vuetify.kda.home.process.title'),
        strComponent: 'h1'
      };
    },
    m_objDescription() {
      return {
        htmlText: this.$vuetify.lang.t('$vuetify.kda.home.process.desc'),
        strComponent: 'h4'
      };
    },
    m_strButton() {
      return this.$vuetify.lang.t('$vuetify.kda.home.process.btn');
    }
  },

  methods: {
    navToProcess() {
      window.gtag('event', 'click', {
        'event_label': 'Detail'
      });
      
      this.$router.push({ name: 'KDAProcess' });
    }
  }
};
</script>
